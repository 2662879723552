import React, { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import House1 from '../img/House/1.jpg';
import SwimP from '../img/House/2.jpg';
import Terrace from '../img/House/3.jpg';
import Bedroom1 from '../img/House/4.jpg';
import Bedroom2 from '../img/House/5.jpg';
import Bedroom3 from '../img/House/6.jpg';
import Studio from '../img/House/7.jpg';
import Bathroom1 from '../img/House/8.jpg';
import Bathroom2 from '../img/House/9.jpg';
import Livingroom from '../img/House/10.jpg';
import Livingroomdining from '../img/House/11.jpg';
import Kitchen from '../img/House/12.jpg';
import Livingroom2 from '../img/House/13.jpg';
import Outdoorkitchen from '../img/House/14.jpg';
import Winterresidence from '../img/House/15.jpg';
import Hallway from '../img/House/16.jpg';
import Lounge from '../img/House/17.jpg';
import BBQ from '../img/House/18.jpg';
import JDB from '../img/House/19.jpg';
import Bench from '../img/House/20.jpg';
import JDBball from '../img/House/21.jpg';
import Chromecast from '../img/House/22.jpg';
import JDB2 from '../img/House/23.jpg';





function Gallery() {
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const images = [
    House1, SwimP, Terrace, Bedroom1, Bedroom2, Bedroom3, Studio, Bathroom1, Bathroom2, Livingroom, Livingroomdining, Kitchen, Livingroom2, Outdoorkitchen,
    Winterresidence, Hallway, Lounge, BBQ, JDB, Bench, JDBball, Chromecast, JDB2
    ];

    // Beschrijvingen voor de alt-teksten
  const altTexts = [
    'Exterieur van het huis', 'Zwembad', 'Terras', 'Slaapkamer 1', 'Slaapkamer 2', 'Slaapkamer 3', 
    'Studio', 'Badkamer 1', 'Badkamer 2', 'Woonkamer', 'Woonkamer met eethoek', 'Keuken', 
    'Woonkamer', 'Buitenkeuken', 'Winterverblijf', 'Hal', 'Lounge', 'BBQ', 'Stenen hoekbank', 
    'Bank', 'Jeux de Boules', 'Chromecast', 'Jeux de Boules baan'
  ];  




  const visibleImages = images.slice(0, 3);
  const remainingImagesCount = images.length - visibleImages.length;

  return (
    <section className="gallery">
      <h2>Gallery</h2>
      <div className="gallery-images">
        {visibleImages.map((src, index) => (
          <img 
            key={index}
            src={src}
            alt={altTexts[index]} // Gebruik hier de alt-tekst
            onClick={() => { setOpen(true); setCurrentImage(index); }}
          />
        ))}
        {remainingImagesCount > 0 && (
          <div 
            className="show-more-img" 
            onClick={() => { setOpen(true); setCurrentImage(visibleImages.length); }}
            style={{ backgroundImage: `url(${images[3]})` }}
          >
            <span>+{remainingImagesCount}</span>
          </div>
        )}
      </div>
      {open && (
        <Lightbox 
          slides={images.map((src, index) => ({ src, alt: altTexts[index] }))} // Alt-teksten hier ook toevoegen
          open={open}
          index={currentImage}
          close={() => setOpen(false)}
        />
      )}
    </section>
  );
}

export default Gallery;