import React from 'react';
import heroImage from '../img/Hero.JPG'; 

const Hero = () => {
  return (
    <section className="hero" style={{ backgroundImage: `url(${heroImage})` }}>
    </section>
  );
}

export default Hero;
