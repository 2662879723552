import React, { useState, useEffect } from 'react';
import { Calendar as MultiDatePickerCalendar } from 'react-multi-date-picker';
import '../App.css'; // Zorg ervoor dat dit pad correct is

const Calendar = () => {
  const [bookings, setBookings] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await fetch('/bookings.json'); // Verwijs naar je JSON-bestand in de public map
        const data = await response.json();
        const parsedData = data.map(booking => ({
          startDate: parseDate(booking.start_date),
          endDate: parseDate(booking.end_date)
        }));
        setBookings(parsedData);
      } catch (error) {
        console.error("Error fetching bookings: ", error);
      }
    };

    fetchBookings();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const parseDate = (dateStr) => {
    const [year, month, day] = dateStr.split('-').map(Number);
    return new Date(year, month - 1, day);
  };

  const isBooked = (date) => {
    return bookings.some(booking => {
      return date >= booking.startDate && date <= booking.endDate;
    });
  };

  return (
    <section className="calendar">
      <h2>Beschikbaarheid</h2>
      
      {/* Legenda Sectie */}
      <div className="legend">
        <div className="legend-item">
          <span className="legend-color booked"></span>
          <span>Geboekt</span>
        </div>
        <div className="legend-item">
          <span className="legend-color available"></span>
          <span>Beschikbaar</span>
        </div>
        <div className="legend-item">
          <span className="legend-color today"></span>
          <span>Vandaag</span>
        </div>
      </div>

      <MultiDatePickerCalendar
        months={isMobile ? 1 : 3}
        value={currentDate}
        mapDays={({ date }) => {
          const jsDate = new Date(date.year, date.month.index, date.day);
          let props = {};
          if (isBooked(jsDate)) {
            props.className = 'booked';
          } else {
            props.className = 'available';
          }
          if (jsDate.toDateString() === new Date().toDateString()) {
            props.className = 'today';
          }
          return props;
        }}
        numberOfMonths={isMobile ? 1 : 3}
      />
    </section>
  );
};

export default Calendar;
